import { notification } from 'antd';

export const notify = (title, description, open, duration) => {
  const config = {
    message: title,
    description,
    duration
  };

  if (open) {
    open(config);
  } else {
    notification.open(config);
  }
};

export default notification;