import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import { Transition } from 'react-spring/renderprops'

import App from 'components/App';

import { 
  Row,
  Col,
  Button,
  notification,
  List,
  Layout,
  Spin,
  Tabs,
  Card,
  Form,
  Input,
  Icon,
  Typography,
  Tooltip
} from 'antd';

import './Splash.css';

import { connect } from 'react-redux';
import actionsCreator from 'redux/actions';

import logo from '../../assets/ic_icon_1.svg';

import client, { 
  VistaClientError,
  AuthenticationError,
} from 'facils/vistaClient';

const { Header, Content, Footer } = Layout;
const { TabPane } = Tabs;
const { Text, Paragraph } = Typography;

const styles = {
  hover: {
    // border-color: rgba(0,0,0,0.09);
    // -webkit-box-shadow: 0 2px 8px rgba(0,0,0,0.09);
    // box-shadow: 0 2px 8px rgba(0,0,0,0.09);
    borderColor: 'rgba(0,0,0,0.06)',
    boxShadow: '0 2px 8px rgba(0,0,0,0.09)'
  }
}

const mapStateToProps = (state) => ({
  event: state.event,
  token: state.app.token,
  user: state.app.user,
});

class Splash extends Component {

  state = {
    loading: true,
  }

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    console.log(this.props);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    this.checkIn();    
  }

  updateWindowDimensions = () => {
    this.setState({ innerHeight: window.innerHeight });
  }

  checkIn = async () => {
    const { dispatch } = this.props;

    this.setState({ loading: true });

    try {
      let { token } = await client.refreshToken();
      await dispatch(actionsCreator.signIn(token));
      let { user } = await client.fetchMe();
      await dispatch(actionsCreator.setUser(user));
      this.setState({ loading: false });
    } catch(err) {
      console.log(err);
      await this.logOut();
      this.setState({ loading: false });
    }
  }

  logOut = async () => {
    const { dispatch } = this.props;

    try {
      await dispatch(actionsCreator.logOut());
    } catch(err) {
      console.log(err);
    }
  }

  notify = (title, description, open, duration) => {
    const config = {
      message: title,
      description,
      duration
    };

    if (open) {
      open(config);
    } else {
      notification.info(config);
    }
  };  

  componentDidUpdate(prevProps) {
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);    
  }  

  render() {
    // const innerHeight = window.innerHeight;    
    const { match, token, user } = this.props;
    const { innerHeight, loading, passcode, email, password } = this.state;

    return (
      <div
        style={{
          position: 'relative',
          height: innerHeight,
          width: '100%'
        }}>
        <Transition
          items={loading}
          from={{ opacity: 1 }}
          enter={{ opacity: 1 }}
          leave={{ opacity: 0 }}>
          {loading => 
            loading
              ? (props =>
                  <Layout style={{ 
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    alignItems: 'center',
                    backgroundColor: '#fff',
                    height: '100%',
                    width: '100%',
                    zIndex: 99,
                    ...props }}>
                    <img 
                      src={logo} 
                      style={{ 
                        height: 'calc(56px + 2vmin)', 
                        marginTop: 'calc(' + innerHeight/2 + 'px - (56px + 2vmin))'}} />                
                  </Layout>)
              : (props => <App/>)
          }
        </Transition>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Splash);