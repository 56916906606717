import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import { 
  Button,
  notification,
  List,
  Layout,
  Spin,
  Card,
  PageHeader,
  Avatar,
  Divider,
  Result,
  Form,
  Input,
  Icon,
  Typography,
  Tooltip
} from 'antd';

import './ForgotPassword.css';

import { connect } from 'react-redux';
import actionsCreator from 'redux/actions';

import client, { 
  UserInputError
} from 'facils/vistaClient';

import logo from '../../assets/ic_icon_1_85percentblack.svg';

const { Header, Content, Footer } = Layout;
const { Text, Paragraph } = Typography;

const styles = {
  hover: {
    // border-color: rgba(0,0,0,0.09);
    // -webkit-box-shadow: 0 2px 8px rgba(0,0,0,0.09);
    // box-shadow: 0 2px 8px rgba(0,0,0,0.09);
    borderColor: 'rgba(0,0,0,0.06)',
    boxShadow: '0 2px 8px rgba(0,0,0,0.09)'
  }
}

const mapStateToProps = (state) => ({
  token: state.app.token,
  user: state.app.user,  
});

class ForgotPassword extends Component {

  state = {
    email: '',
    sent: false,
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log(this.props);
  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  }  

  handleSubmit = (e) => {
    e.preventDefault();

    const email = this.state.email.trim();

    if (!email) {
      this.notify('Warning', 'Email is required', notification.warning, 2);
    } else {
      this.requestPasswordReset(email);
    }
  }

  requestPasswordReset = async (email) => {
    this.setState({ loading: true });

    try {
      await client.requestPasswordReset(email);
      this.setState({ loading: false, sent: true });      
      this.notify('Reset Link Sent!', 'Check your email for a link to reset your password', notification.success, 8);            
    } catch(err) {
      this.setState({ loading: false });
      if (err instanceof UserInputError) {
        this.notify("Info", <span>Are you sure that email address is correct?<br/>If so, you will receive an email soon.</span>, notification.info, 8);
      } else {
        this.notify("Something went wrong", "Your password cannot be reset at this time", notification.error, 5);        
      }
    }
  }

  notify = (title, description, open, duration) => {
    const config = {
      message: title,
      description,
      duration
    };

    if (open) {
      open(config);
    } else {
      notification.info(config);
    }
  };  

  componentWillUnmount () {
  }  

  render() {
    const { history } = this.props;
    const { email, loading, sent } = this.state;
    const innerWidth = window.innerWidth;    

    return (
      <div
        style={{
          width: '100%',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: innerWidth <= 448+8? 'normal' : 'center',
          paddingBottom: innerWidth <= 448+8? '0' : '5%',
          alignItems: 'center'
        }}>
        <Card
          style={{ 
            position: 'relative',
            // top: '-5%',
            width: '100%',
            maxWidth: 448,
            ...styles.hover}}>
          {/*<div 
            style={{ 
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'}}>
            <img src={logo} style={{ width: '72px' }} /> 
          </div>          
          <div style={{ textAlign: 'center', fontSize: 20, margin: '24px 0px 24px 0px'  }}>
            <Text>Reset Password</Text>
          </div>*/}
          {/*<Divider style={{ margin: 0 }} />*/}      
          <div 
            style={{ 
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 24}}>
            <Text style={{ fontSize: 20 }}>Reset Password</Text>     
            <img src={logo} style={{ width: 32, marginRight: 12 }} />
          </div>          
          <div>

          </div>        
          <div>
            <Form
              layout='vertical'
              onSubmit={this.handleSubmit}>
              <Form.Item
                label=''
                validateStatus='success'
                help=''
                style={{paddingBottom: 0, marginBottom: 16}}
                >
                <Input className="custom-input-prefix-padding" size="large"  placeholder="Email" prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />} value={email} disabled={loading} onChange={this.handleEmailChange} allowClear />
              </Form.Item>
              <Button htmlType="submit" type="primary" size="large" onClick={this.handleSubmit} loading={loading} style={{width: '100%', marginTop: 16}}>{sent? 'Resend Link' : 'Send Reset Link'}</Button>
            </Form>
          </div>
          <div 
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 24,
              }}>
            <Link  to='/login'><Text>Return to login</Text></Link>
          </div>
        </Card>
      </div>
    );    
  }
}

export default connect(mapStateToProps)(ForgotPassword);