import {
	EVENT
} from '../actionTypes';

const initialState = {}

export default function(state = initialState, action) {
	const { type, payload } = action;

	switch(type) {
		case EVENT:
			return payload;

		default:
			return state;
	}
}