import gql from 'graphql-tag';

export const AUTHENTICATE = gql`
  mutation AuthenticateUser(
    $passcode: String!
  ) {
    authenticate(
      passcode: $passcode
    ) {
      token
    }
  }
`;

export const LOG_IN = gql`
  mutation(
    $email: String!
    $password: String!
  ){
    logIn(
      email: $email,
      password: $password
    ) {
      token
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation {
    refreshToken {
      token
    }
  }
`;

export const REQUEST_PASSWORD_RESET = gql`
  mutation(
    $email: String!
  ) {
    requestPasswordReset(
      email: $email
    )
  }
`;

export const RESET_PASSWORD = gql`
  mutation(
    $token: String!
    $password: String!
  ) {
    resetPassword(
      token: $token
      password: $password
    ) {
      token
      passcode
    }
  }
`

export const GET_ME = gql`
  query GetMe {
    user {
      id
      email
      verified
      name
      omg
    }
  }
`;

export const GET_PROJECTS = gql`
  query GetProjects {
    projects {
      id
      name
      omg      
      model {
        id
        quantized
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_PROJECT = gql`
  query GetProject(
    $id: ID!
  ) {
    project(
      id: $id
    ) {
      id
      name
      omg
      description
      owner {
        id
      }      
      model {
        id
        quantized
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation CreateProject(
    $data: ProjectCreateInput!
  ) {
    createProject(
      data: $data
    ) {
      id
      name
      omg
      description
      public
      upload
      model {
        id
        quantized
        width
        height
        pixelSize
        flat
        excludeDim
        invert
        grayscale
        threshold
        maxResults
        sourceType
        modelSource
        labelsSource
        createdAt
        updatedAt
      }
      samplePosts {
        id
        image
        tags {
          id
          name
          score
        }
      }
      createdAt
      updatedAt
    }
  }
`;