import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import { DragDrop, ProgressBar, StatusBar } from '@uppy/react';
import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import '@uppy/progress-bar/dist/style.css'
import '@uppy/status-bar/dist/style.css'

import { 
  Button,
  notification,
  List,
  Layout,
  Spin,
  Card,
  PageHeader,
  Avatar,
  Divider,
  Result,
  Typography
} from 'antd';

import './Project.css';

import { connect } from 'react-redux';
import actionsCreator from 'redux/actions';

import client, { 
  VistaClientError,
  AuthenticationError,
} from 'facils/vistaClient';

import filesize from 'filesize';

const { Header, Content, Footer } = Layout;
const { Text, Paragraph } = Typography;

const url = process.env.REACT_APP_VISTA_API_BASE_URL;
const production = process.env.NODE_ENV === 'production';

const styles = {
  hover: {
    // border-color: rgba(0,0,0,0.09);
    // -webkit-box-shadow: 0 2px 8px rgba(0,0,0,0.09);
    // box-shadow: 0 2px 8px rgba(0,0,0,0.09);
    borderColor: 'rgba(0,0,0,0.06)',
    boxShadow: '0 2px 8px rgba(0,0,0,0.09)'
  }
}

const mapStateToProps = (state) => ({
  token: state.app.token,
  user: state.app.user,  
  projects: state.projects,
});

class Project extends Component {

  state = {
    files: [],
    project: null,
    fetchingProject: false,
    fetchingUser: false,
    completed: false,
  }

  constructor(props) {
    super(props);

    const projectId = props.match.params.projectId || '';
    const token = props.token;
    this.setUppy(projectId, token);
  }

  setUppy = (projectId, token) => {
    this.uppy = new Uppy({ 
      id: 'uppy1',
      autoProceed: false,
      allowMultipleUploads: true,
      restrictions: {
        maxNumberOfFiles: 2,
        minNumberOfFiles: 2,
        allowedFileTypes: ['text/plain', '.tflite', '.lite']
      },
      debug: !production
    })
    .use(XHRUpload, { 
      endpoint: `${url}/projects/${projectId}/artifacts`,
      method: 'put',
      formData: true,
      bundle: true,
      fieldName: 'files[]',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      timeout: 10 * 60 * 1000,
      getResponseError (responseText, response) {
        console.log('getResponseError responseText', responseText);
        console.log('getResponseError response', response);
        switch(response.status) {
          case 400:
          case 401:
          case 403:
          case 404:
          case 413:
            var data = JSON.parse(responseText);
            var message = data.message
            return new Error(message);
          default: 
            var message = "Please check your connection and try again";
            return new Error(message);
        }
      }
    })
    .on('file-added', (file) => {
      console.log(file);
      this.setState((state) => {
        // return { files: [...state.files, file] }
        return { files: this.uppy.getFiles() }
      });
    })
    .on('file-removed', (file) => {
      this.setState((state) => {
        // return { files: state.files.filter(f => f.id !== file.id) }
        return { files: this.uppy.getFiles() }        
      });
    })
    .on('info-visible', () => {
      const info = this.uppy.getState().info;
      const error = this.uppy.getState().error;      
      // info: {
      //  isHidden: false,
      //  type: 'error',
      //  message: 'Failed to upload',
      //  details: 'Error description'
      // }
      console.log('uppy.on(info-visible)', info)
      console.log('uppy.on(info-visible)', error)      
      if (!error) {
        this.notify("Note", info.message, notification.info);
      // } else {
      //   this.notify("Something went wrong", error, notification.error);        
      }
    })
    .on('info-hidden', () => {
      const info = this.uppy.getState().info
      console.log('uppy.on(info-hidden)', info)
    })
    .on('error', () => {
      const error = this.uppy.getState().error;
      console.log(this.uppy.getState());
      console.log('uppy.on(error): ', error);
      this.notify("Sorry", error, notification.warning)
    })
    .on('complete', (result) => {
      console.log('successful files:', result.successful);
      console.log('failed files:', result.failed);
      this.setState({ completed: true });
    });
  }

  componentDidMount() {
    console.log(this.props);
    const { match, user, projects } = this.props;    
    const projectId = match.params.projectId;
    const project = projects[projectId];

    // if (!project) {
    //   this.fetchProject(projectId);
    // } else {
    //   this.setState({ project });
    // }

    this.fetchProjectThenUser(projectId);
  }

  fetchProjectThenUser = async (id) => {
    this.setState({ fetchingProject: true });

    try {
      let { project } = await client.fetchProject(id);
      console.log(project);
      this.setState({ project, fetchingProject: false });
      this.fetchMe();
    } catch(err) {
      this.setState({ fetchingProject: false });
      this.notify("Error", err.message, notification.error, 5);
    }
  }

  fetchMe = async () => {
    const { dispatch } = this.props;
    var { user } = this.props;

    if (!user) {
      this.setState({ fetchingUser: true });    

      try {
        ({ user } = await client.fetchMe());
        await dispatch(actionsCreator.setUser(user));
        this.setState({ fetchingUser: false });            
      } catch(err) {
        this.setState({ fetchingUser: false });
        this.notify("Error", err.message, notification.error, 5);
        return;
      }
    }

    if (!this.isOwner()) {
      this.notify("Error", "Either you do not have access to this project, or it does not exist.", notification.error, 10);      
    }
  }

  isOwner = () => {
    const { user } = this.props;
    const { project } = this.state;
    return project && project.owner && project.owner.id && user && project.owner.id === user.id    
  }

  resetUppy = () => {
    this.uppy.reset();
    this.setState({ completed: false });
  }

  notify = (title, description, open, duration) => {
    const config = {
      message: title,
      description,
      duration
    };

    if (open) {
      open(config);
    } else {
      notification.info(config);
    }
  };  

  componentWillUnmount () {
    this.uppy.close();
  }  

  renderFileItem = (file) => {
    return (
      <List.Item
        actions={[<a onClick={() => this.uppy.removeFile(file.id)}>Remove</a>]}>
        <div style={{ flex: '50%' }}>{file.name}</div>
        <div style={{ flex: '50%' }}>{filesize(file.size)}</div>
      </List.Item>
    );
  }

  render() {
    const { history, user } = this.props;
    const { files, project, fetchingProject, fetchingUser, completed } = this.state;

    const loading = fetchingProject || fetchingUser;
    const owner = this.isOwner();

    return (
      <Card
        bodyStyle={{ padding: '0px' }}
        style={{ 
          display: 'flex', 
          flexDirection: 'column', 
          width: '100%',
          maxWidth: 512,
          top: '10%',
          ...styles.hover}}>
        <PageHeader 
          title={
            <div style={{ display: 'inline-block' }}>
              <Avatar src={owner? project.omg : null} shape='square' size={20} style={{ marginRight: 16, marginBottom: 4 }}/>
              {owner? project.name : ""}
            </div>
          }
          onBack={() => history.push('/projects')}/>
        <Divider style={{ margin: 0 }} />
        <div style={{ padding: '24px' }}>
          <Spin spinning={loading}>
            {owner
              ? completed
              ? (<Result
                  status="success"
                  title="Assets Uploaded!"
                  subTitle="Visit the app to tune your project's settings and launch the model"
                  extra={[
                    <Button type="primary" key="0" onClick={() => history.push('/projects')}>Go Back to Projects</Button>,
                    <Button key="1" onClick={() => this.resetUppy()}>Upload Different Assets</Button>,
                    ]}/>)
              : (<div>
                  <Paragraph style={{ fontSize: 16 }}>Upload model and label assets:</Paragraph>
                  <DragDrop
                      uppy={this.uppy}
                      height="172px"
                      note='Select 1 model file (.tflite) and 1 labels file (.txt). 50 MB limit.'
                      locale={{
                        strings: {
                          dropHereOr: 'Drop assets here or %{browse}',
                          browse: 'browse',
                        }
                      }}/>
                  {files.length
                  ? (<List
                    size="small"
                    bordered={false}
                    dataSource={files}
                    renderItem={this.renderFileItem}
                    style={{ padding: '12px 24px 12px 24px' }}/>)
                  : null}                       
                  <StatusBar
                    uppy={this.uppy}
                    showProgressDetails
                    hideAfterFinish={false}
                    hideRetryButton={true}
                    hidePauseResumeButton={true}/> 
                </div>)
              : (<div></div>)}
          </Spin>
        </div>
      </Card>
    );    
  }
}

export default connect(mapStateToProps)(Project);