import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { 
  Button,
  notification,
  List,
  Layout,
  Spin,
  Card,
  PageHeader,
  Avatar,
  Divider,
  Result,
  Form,
  Input,
  Icon,
  Typography,
  Tooltip
} from 'antd';

import './ResetPassword.css';

import { connect } from 'react-redux';
import actionsCreator from 'redux/actions';

import client, { 
  UserInputError,
  AuthenticationError
} from 'facils/vistaClient';

import logo from '../../assets/ic_icon_1_85percentblack.svg';

const { Header, Content, Footer } = Layout;
const { Text, Paragraph } = Typography;

const fallbackUrl = process.env.REACT_APP_BRANCH_RESET_PASSWORD_FALLBACK;

const styles = {
  hover: {
    // border-color: rgba(0,0,0,0.09);
    // -webkit-box-shadow: 0 2px 8px rgba(0,0,0,0.09);
    // box-shadow: 0 2px 8px rgba(0,0,0,0.09);
    borderColor: 'rgba(0,0,0,0.06)',
    boxShadow: '0 2px 8px rgba(0,0,0,0.09)'
  }
}

const mapStateToProps = (state) => ({
  token: state.app.token,
  user: state.app.user,  
});

class ResetPassword extends Component {

  state = {
    resetId: null,
    newPassword: '',
    confirmPassword: '',
    saving: false,
    saved: false,
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log(this.props);
    const { match } = this.props;    
    const resetId = match.params.resetId;

    if (resetId) {
      this.setState({ resetId });      
    }
  }

  handleNewPasswordChange = (e) => {
    this.setState({ newPassword: e.target.value });
  }

  handleConfirmPasswordChange = (e) => {
    this.setState({ confirmPassword: e.target.value });
  }  

  handleSave = (e) => {
    e.preventDefault();

    const resetId = this.state.resetId;
    const newPassword = this.state.newPassword.trim();
    const confirmPassword = this.state.confirmPassword.trim();

    if (!newPassword || !confirmPassword) {
      this.notify('Warning', 'Please enter and confirm your new password', notification.warning);
    } else if (newPassword !== confirmPassword) {
      this.notify('Warning', 'Passwords must match', notification.warning);
    } else {
      this.resetPassword(resetId, newPassword);
    }
  }

  resetPassword = async (resetId, password) => {
    const { dispatch } = this.props;

    if (!resetId) {
      this.notify("Sorry", "Your reset link has expired", notification.warning);      
      return;
    }

    if (!password)
      return;

    this.setState({ saving: true });

    try {
      const { token } = await client.resetPassword(resetId, password);
      dispatch(actionsCreator.logOut());
      this.setState({ saving: false, saved: true });
      // this.notify('New Password Saved!', 'Please log in', notification.success, 2);
    } catch(err) {
      console.log(err);
      this.setState({ saving: false });
      if (err instanceof AuthenticationError) {
        this.notify("Sorry", "Your reset link has expired", notification.warning);
      } else {
        this.notify("Sorry", err.message, notification.error);
      }
    }
  }

  notify = (title, description, open, duration) => {
    const config = {
      message: title,
      description,
      duration
    };

    if (open) {
      open(config);
    } else {
      notification.info(config);
    }
  };  

  componentWillUnmount () {
  }  

  render() {
    const { history } = this.props;
    const { newPassword, confirmPassword, saving, saved } = this.state;
    const innerWidth = window.innerWidth;
    return (
      <div
        style={{
          width: '100%',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: innerWidth <= 448+8? 'normal' : 'center',
          paddingBottom: innerWidth <= 448+8? '0' : '5%',
          alignItems: 'center'
        }}>
        <Card
          style={{ 
            position: 'relative',
            // top: '-5%',
            width: '100%',
            maxWidth: 448,
            ...styles.hover}}>  
          <div 
            style={{ 
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 24}}>
            <Text style={{ fontSize: 20 }}>{saved? 'New Password Saved' : 'Create a New Password'}</Text>     
            <img src={logo} style={{ width: 32, marginRight: 12 }} />
          </div>
          {saved
            ? (<div>
                <div style={{fontSize: 18, textAlign: 'left'}}>
                  <Text>Your password has been reset. For security, </Text><br/><Text>please log back in.</Text>
                </div>        
                <div>
                    <a href={fallbackUrl}><Button type="primary" size="large" style={{width: '100%', marginTop: 24}}>Log In</Button></a>
                </div>
              </div>)
            : (<div>
                <div>
                  <Form
                    layout='vertical'
                    onSubmit={this.handleSave}>
                    <Form.Item
                      label=''
                      validateStatus='success'
                      help=''
                      style={{paddingBottom: 0, marginBottom: 16}}
                      >
                      <Input className="custom-input-prefix-padding" size="large" type="password"  placeholder="New password" prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} value={newPassword} disabled={saving} onChange={this.handleNewPasswordChange} allowClear />
                    </Form.Item>
                    <Form.Item
                      label=''
                      validateStatus='success'
                      help=''
                      style={{paddingBottom: 0, marginBottom: 16}}
                      >
                      <Input className="custom-input-prefix-padding" size="large" type="password"  placeholder="Confirm password" prefix={<Icon type="lock" theme="filled" style={{ color: 'rgba(0,0,0,.25)' }} />} value={confirmPassword} disabled={saving} onChange={this.handleConfirmPasswordChange} allowClear />                
                    </Form.Item>              
                    <Button htmlType="submit" type="primary" size="large" onClick={this.handleSave} loading={saving} disabled={true? false : !newPassword || !confirmPassword} style={{width: '100%', marginTop: 16}}>Save</Button>
                  </Form>
                </div>
                <div 
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 24,
                    }}>
                  <Link  to='/login'><Text>Return to login</Text></Link>
                </div>
              </div>)
          }      
        </Card>
      </div>);    
  }
}

export default connect(mapStateToProps)(ResetPassword);