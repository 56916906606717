import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import { DragDrop, ProgressBar, StatusBar } from '@uppy/react';
import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import '@uppy/progress-bar/dist/style.css'
import '@uppy/status-bar/dist/style.css'

import { 
  Button,
  notification,
  List,
  Layout,
  Row,
  Col,
  Spin,
  Card,
  PageHeader,
  Avatar,
  Divider,
  Tooltip,
  ConfigProvider,
  Icon,
  Empty
} from 'antd';

import { connect } from 'react-redux';
import actionsCreator from 'redux/actions';

import client, { 
  VistaClientError,
  AuthenticationError,
} from 'facils/vistaClient';

const { Header, Content, Footer } = Layout;

const styles = {
  hover: {
    // border-color: rgba(0,0,0,0.09);
    // -webkit-box-shadow: 0 2px 8px rgba(0,0,0,0.09);
    // box-shadow: 0 2px 8px rgba(0,0,0,0.09);
    borderColor: 'rgba(0,0,0,0.06)',
    boxShadow: '0 2px 8px rgba(0,0,0,0.09)'
  }
}

const mapStateToProps = (state) => ({
  projects: state.projects,
  user: state.app.user,
});

class Projects extends Component {

  state = {
    loading: false
  }

  promises = [];

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log(this.props);

    const { projects } = this.props;

    // if (!projects || !Object.keys(projects).length) {
    //   this.fetchProjects();
    // }

    this.fetchProjects();
  }

  fetchProjects = async () => {
    console.log('Projects.fetchProjects');    
    const { dispatch } = this.props;

    this.setState({ loading: true });

    try {
      let fetchProjectsPromise = client.fetchProjects().cancelable();
      this.promises.push(fetchProjectsPromise);

      let { projects } = await fetchProjectsPromise;
      console.log(projects);      
      await dispatch(actionsCreator.addProjectEntities(projects));
      this.setState({ loading: false });
    } catch(err) {
      console.log(err, err instanceof Error);
      this.setState({ loading: false });
      this.notify("Something went wrong", String(err), notification.error);
    }
  };

  handleClick = () => {
    this.fetchProjects();
  }

  notify = (title, description, open, duration) => {
    const config = {
      message: title,
      description,
      duration
    };

    if (open) {
      open(config);
    } else {
      notification.info(config);
    }
  };  

  componentWillUnmount() {
    this.promises.forEach(p => p.cancel());
  }

  renderProjectItem = (item) => {
    const { match } = this.props;
    return (
      <List.Item>
        <Button
          block
          type="link"
          style={{ textAlign: 'left', padding: 0, fontSize: 16, color: '#2275d7' }}>
          <Link to={`${match.url}/${item.id}`}>
            <Avatar src={item.omg} shape='square' style={{ marginRight: 16 }} />
            {item.name}
          </Link>
        </Button>
      </List.Item>
    );
  }

  renderEmpty = () => {
    const { history } = this.props;
    return (
      <Empty
        description="You haven't created any projects yet"
        style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
        <Button type="primary" onClick={() => history.push('/new-project')}>Create a New Project</Button>
      </Empty>
    );  
  }

  render() {
    const { match, history, user, projects } = this.props;
    const { loading } = this.state;

    let projectsList = projects? Object.values(projects) : [];

    return (      
      <Card
        style={{ 
          width: '100%',
          maxWidth: 512,
          top: '10%',
          height: 'auto',
          maxHeight: 400,
          display: 'flex', 
          flexDirection: 'column',
          ...styles.hover}}
        bodyStyle={{ 
          padding: '0px',
          flex: '1 1 auto',
          display: 'flex', 
          flexDirection: 'column',
          overflow: 'hidden'}}>
        <PageHeader 
          backIcon={false}
          title="Projects"
          extra={[
            <Tooltip key="0"  title="New Project" mouseEnterDelay={1}>
              <Button icon="plus" onClick={() => history.push('/new-project')} style={{ top: -5 }}/>
            </Tooltip>,
          ]}
          style={{ flex: '1 0 auto' }}/>
        <Divider style={{ margin: 0, flex: '1 0 auto' }} />              
        <div style={{ padding: '0px 24px', flex: '1 1 auto', overflow: 'auto'}}>
          <ConfigProvider renderEmpty={this.renderEmpty}>
            <List
              bordered={false}
              split={false}
              dataSource={projectsList}
              loading={loading && projectsList.length == 0}
              renderItem={this.renderProjectItem}
              style={{ flex: '1 1 auto' }}/>
          </ConfigProvider>
        </div>
      </Card>
    );
  }
}

export default connect(mapStateToProps)(Projects);